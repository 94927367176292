import { getCareerExplorerHostFromHostname } from "@/modules/api/helpers/getCareerExplorerHostFromHostname"
import { getMonolithHostFromHostname } from "@/modules/api/helpers/getMonolithHostFromHostname"

export type ServicesDomain =
  | "CAREER_EXPLORER_API"
  | "CONNECT_API"
  | "EXTERNAL"
  | "INTERNAL_NEXT_API"
  | "LEARNING_API"
  | "KRAKEND_API"
  | "MONOLITH_API"
  | "SEARCH_PROXY"
  | "SEARCH_PROXY_V3"
  | "APIGW_IN_KRAKEND_API"

type DefaultApiUrlParams = {
  path: string
  serviceDomain: Exclude<ServicesDomain, "MONOLITH_API">
}

type MonolithApiUrlParams = {
  hostname: string
  path: string
  serviceDomain: "MONOLITH_API"
}

type CareerExplorerApiUrlParams = {
  hostname: string
  path: string
  serviceDomain: "CAREER_EXPLORER_API"
}

type ApiUrlParams = MonolithApiUrlParams | CareerExplorerApiUrlParams | DefaultApiUrlParams

export const getApiUrl = (params: ApiUrlParams = { path: "", serviceDomain: "KRAKEND_API" }): string => {
  const { path = "", serviceDomain } = params

  if (serviceDomain === "MONOLITH_API") {
    const { hostname } = params as MonolithApiUrlParams

    if (typeof window === "undefined" && process.env.NEXT_PUBLIC_ENV !== "local") {
      return `${process.env.INTERNAL_MONOLITH_API_BASE_URL}${path}`
    }

    if (!hostname) {
      throw new TypeError(`You must specify an hostname when using domain "MONOLITH_API"`)
    }

    const monolithDomain = getMonolithHostFromHostname(hostname)
    const baseUrl = (process.env.NEXT_PUBLIC_MONOLITH_API_BASE_URL || "").replace(
      /(https?:\/\/).*/,
      (_match, value) => `${value}${monolithDomain}`
    )

    return `${baseUrl}${path}`
  }

  if (serviceDomain === "CONNECT_API") {
    const connectDomain =
      typeof window === "undefined" && process.env.NEXT_PUBLIC_ENV !== "local"
        ? process.env.CONNECT_INTERNAL_DOMAIN
        : process.env.CONNECT_DOMAIN

    return `${connectDomain}${path}`
  }

  if (serviceDomain === "CAREER_EXPLORER_API") {
    const { hostname } = params as CareerExplorerApiUrlParams

    if (!hostname) {
      throw new TypeError(`You must specify an hostname when using domain "CAREER_EXPLORER_API"`)
    }

    const careerExplorerDomain = getCareerExplorerHostFromHostname(hostname)
    const baseUrl = (process.env.NEXT_PUBLIC_CAREER_EXPLORER_API_BASE_URL || "").replace(
      /(https?:\/\/).*/,
      (_match, value) => `${value}${careerExplorerDomain}`
    )

    return `${baseUrl}${path}`
  }

  if (serviceDomain === "KRAKEND_API" || serviceDomain === "APIGW_IN_KRAKEND_API") {
    if (typeof window === "undefined" && process.env.NEXT_PUBLIC_ENV !== "local") {
      return `${process.env.INTERNAL_KRAKEND_API_BASE_URL}${path}`
    }

    return `${process.env.NEXT_PUBLIC_KRAKEND_API_BASE_URL}${path}`
  }

  if (serviceDomain === "LEARNING_API") {
    return `${process.env.NEXT_PUBLIC_LEARNING_API_BASE_URL}${path}`
  }

  if (serviceDomain === "SEARCH_PROXY") {
    if (typeof window === "undefined" && process.env.NEXT_PUBLIC_ENV !== "local") {
      return `${process.env.INTERNAL_ALGOLIA_CUSTOM_HOST_URL}${path}`
    }
    return `${process.env.NEXT_PUBLIC_ALGOLIA_CUSTOM_HOST_URL}${path}`
  }

  if (serviceDomain === "INTERNAL_NEXT_API" || serviceDomain === "EXTERNAL") {
    return path
  }

  throw new TypeError(
    `Expected serviceDomain to be one of:
    | "CAREER_EXPLORER_API"
    | "CONNECT_API"
    | "EXTERNAL"
    | "INTERNAL_NEXT_API"
    | "KRAKEND_API"
    | "LEARNING_API"
    | "MONOLITH_API"
    | "SEARCH_PROXY"
    | "SEARCH_PROXY_V3"
    | "APIGW_IN_KRAKEND_API"
    but got "${serviceDomain}"`
  )
}
